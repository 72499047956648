.footer {
  border-top: 1px solid #fff;
  font-size: 12px;
  padding: 20px;
  display: flex;
  margin-top: 20px; }
  .footer--logo img {
    width: 100%;
    max-width: 130px; }
  .footer__address {
    display: flex !important;
    flex-direction: column; }
  .footer a {
    text-decoration: none;
    color: #fff; }
  @media screen and (max-width: 767px) {
    .footer {
      display: none; } }

.social-icons {
  display: flex !important;
  flex-direction: column;
  align-items: flex-end; }
  .social-icons img {
    width: auto;
    height: 24px;
    margin-left: 10px; }
    .social-icons img:hover {
      opacity: 0.7; }
  .social-icons--link {
    margin-top: 10px; }

.social-wrapper {
  display: flex;
  justify-content: flex-end; }

.mobile-footer {
  border-top: 1px solid #fff;
  font-size: 9px;
  padding: 15px 10px; }
  .mobile-footer a {
    text-decoration: none;
    color: #fff; }
  .mobile-footer--logo img {
    width: 100px; }
  .mobile-footer--row {
    margin-bottom: 15px; }
  .mobile-footer__imprint {
    display: flex;
    justify-content: space-between;
    width: 100%; }
  @media screen and (min-width: 768px) {
    .mobile-footer {
      display: none; }
      .mobile-footer--logo img {
        width: 130px; } }
