body {
  background-color: #000035;
  color: #fff;
  font-family: Carloschi; }

.container {
  max-width: 1280px;
  margin: 0 auto; }

.pure-g {
  font-family: Carloschi; }

.home {
  height: 100vh;
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 1024px) {
    .home {
      overflow: hidden; } }

@media screen and (min-width: 1024px) {
  .hide-desktop {
    display: none !important; } }

@media screen and (max-width: 1023px) {
  .hide-mobile {
    display: none !important; } }

.nav-link {
  color: #fff;
  font-weight: 600;
  letter-spacing: 2px;
  margin: 0 22px;
  text-decoration: none; }

.wrapper {
  padding: 20px; }

@font-face {
  font-family: 'Carloschi';
  src: url("../assets/fonts/Carloschi/Carloschi-Regular.woff2") format("woff2"), url("../assets/fonts/Carloschi/Carloschi-Regular.woff") format("woff");
  font-weight: normal; }

@font-face {
  font-family: 'Carloschi';
  src: url("../assets/fonts/Carloschi/Carloschi-SemiBold.woff2") format("woff2"), url("../assets/fonts/Carloschi/Carloschi-SemiBold.woff") format("woff");
  font-weight: 600; }

@font-face {
  font-family: 'Carloschi';
  src: url("../assets/fonts/Carloschi/Carloschi-Bold.woff2") format("woff2"), url("../assets/fonts/Carloschi/Carloschi-Bold.woff") format("woff");
  font-weight: 800; }

@font-face {
  font-family: 'Carloschi';
  src: url("../assets/fonts/Carloschi/Carloschi-Light.woff2") format("woff2"), url("../assets/fonts/Carloschi/Carloschi-Light.woff") format("woff");
  font-weight: 200; }
