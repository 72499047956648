.header {
  display: flex;
  font-size: 10px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 3px;
  padding: 30px 30px 0 30px;
  text-transform: uppercase;
  font-family: Carloschi !important; }
  .header--logo {
    width: 100px;
    margin-top: 15px; }
  .header--logo-wrapper {
    display: flex !important;
    justify-content: flex-start; }
    @media screen and (min-width: 1024px) {
      .header--logo-wrapper {
        justify-content: center; } }
  .header--study {
    padding: 0 22px;
    letter-spacing: 3px;
    margin-block-start: 0; }
  .header--navigation {
    display: flex !important;
    justify-content: flex-end;
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0; }
  @media screen and (min-width: 768px) {
    .header--logo {
      width: 130px;
      margin-top: 20px; } }
  @media screen and (min-width: 1024px) {
    .header {
      padding-top: 45px;
      justify-content: space-between; }
      .header--logo {
        margin-top: 0; } }
