.special-button {
  align-items: center;
  background-image: url("../assets/images/spray_button.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 70px;
  justify-content: center;
  letter-spacing: 3px;
  text-transform: uppercase; }
  .special-button:hover {
    cursor: pointer; }
  .special-button a {
    color: #fff;
    text-decoration: none; }
