.about {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 70px 0 55px; }
  .about__headline {
    align-items: center;
    background-image: url("../assets/images/background_equal_logo.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    height: 180px;
    justify-content: center;
    margin-bottom: 55px; }
    .about__headline--title {
      font-size: 18px;
      font-weight: 800;
      letter-spacing: 2px;
      line-height: 30px;
      max-width: 75%;
      text-align: 'center';
      text-transform: uppercase; }
  .about__subheadline {
    font-size: 14px;
    letter-spacing: 4px;
    margin: 70px 0;
    text-transform: uppercase; }
  .about__partner {
    align-items: center; }
    .about__partner--logo {
      width: 200px; }
    .about__partner--logo-wrapper {
      padding: 20px 0; }
  .about__expertise {
    font-size: 16px;
    font-weight: 200;
    text-transform: uppercase; }
    .about__expertise:last-of-type {
      margin-bottom: 75px; }
  .about__text {
    font-size: 16px;
    font-weight: 200;
    margin: 25px auto 0;
    width: 70%; }
    .about__text:first-of-type {
      margin: 0 auto; }
  @media screen and (min-width: 468px) {
    .about__headline {
      height: 220px; } }
  @media screen and (min-width: 1024px) {
    .about__headline {
      height: 280px; } }
