.newsletter {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1; }
  .newsletter--mobile-title {
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 3px;
    margin: 40px 0;
    order: -1;
    text-align: center;
    text-transform: uppercase; }
  .newsletter__headline--title {
    margin: 80px 0 20px 0;
    letter-spacing: 4px;
    text-align: center; }
  .newsletter--text {
    text-align: center; }
  .newsletter__download--button {
    height: 70px;
    border: 0 none;
    background-color: transparent;
    background-image: url("../assets/images/spray_button.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    letter-spacing: 3px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 12px;
    line-height: 32px;
    margin: 0 5px 10px 0;
    padding: 0 22px;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap; }
  .newsletter__download--link {
    text-decoration: none;
    color: white; }
  .newsletter__download--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  @media screen and (min-width: 768px) {
    .newsletter__download--button {
      height: 70px;
      width: 320px;
      margin: 20px 20px; }
    .newsletter__download--wrapper {
      display: inline !important; } }

@media screen and (min-width: 768px) and (min-width: 1024px) {
  .newsletter--mobile-title {
    display: none; } }
