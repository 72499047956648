.mobilenavigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%; }
  .mobilenavigation:focus {
    outline: none; }
  .mobilenavigation__icon {
    height: 30px;
    fill: #fff;
    padding: 10px 16px; }
    .mobilenavigation__icon-close {
      background-color: #fff;
      fill: #000035; }
    .mobilenavigation__icon--wrapper {
      width: fit-content; }
      .mobilenavigation__icon--wrapper:hover {
        cursor: pointer; }
  .mobilenavigation__list {
    background-color: #fff;
    list-style: none;
    flex-direction: column !important;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 30px 0;
    position: absolute;
    text-align: center;
    top: calc(100% - 3px);
    width: calc(100vw - 60px); }
    .mobilenavigation__list li {
      padding: 10px 0;
      pointer-events: all; }
      .mobilenavigation__list li:hover {
        cursor: pointer; }
    .mobilenavigation__list .nav-link {
      color: #000035;
      font-size: 20px;
      line-height: 24px;
      margin: 0; }
  @media screen and (min-width: 1023px) {
    .mobilenavigation {
      display: none !important; } }
