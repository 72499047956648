.landingpage {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 55px 0; }
  .landingpage--logo {
    width: 100%; }
  .landingpage--logo-wrapper {
    align-items: center;
    order: -1;
    flex-grow: 1; }
  .landingpage--download-wrapper {
    flex-grow: 2;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 15px;
    text-align: center;
    margin: 20px; }
  .landingpage--mobile-title {
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 3px;
    margin: 40px 0;
    order: -1;
    text-align: center;
    text-transform: uppercase; }
  .landingpage__download--link {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    line-height: 2; }
  @media screen and (min-width: 768px) {
    .landingpage--download-wrapper {
      flex-grow: 1; } }
  @media screen and (min-width: 1024px) {
    .landingpage {
      flex-direction: row; }
      .landingpage--logo-wrapper {
        order: 0;
        width: 50%; }
      .landingpage--download-wrapper {
        width: 25%;
        display: flex !important;
        justify-content: center; }
      .landingpage--mobile-title {
        display: none; } }
  @media screen and (min-width: 600px) and (max-width: 1023px) {
    .landingpage {
      min-height: 100vh; }
      .landingpage--download-wrapper {
        max-height: 100px; } }
  @media screen and (max-width: 400px) {
    .landingpage__download--link {
      font-size: 12px; } }
